import {BrowserRouter as Router} from "react-router-dom";
import Public from "./Routes/Public";


function App() {
  return (
    <div >
     <Router>
        <Public/>
      </Router>

    </div>
  );
}

export default App;
